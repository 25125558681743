import { IonCol, IonGrid, IonIcon, IonRow } from "@ionic/react";
import "./Footer.css";

const Footer: React.FC = () => {
  return (
    <footer>
      <IonGrid style={{ background: "rgb(29 29 29)", color: "#fff" }}>
        <IonRow>
          <IonCol sizeMd="3" sizeSm="12" sizeXs="12">
            <article className="info">
              <h4>Company</h4>
              <ul>
                <li>
                  <a href="http://vizagbikerentals.com">About Us</a>
                </li>
                <li>
                  <a href="http://vizagbikerentals.com">Privacy Policy</a>
                </li>
                <li>
                  <a href="http://vizagbikerentals.com">Terms & Conditions</a>
                </li>
              </ul>
            </article>
          </IonCol>
          <IonCol sizeMd="3" sizeSm="12" sizeXs="12">
            <article className="info">
              <h4>Our Presence</h4>
              <ul className="footer_links">
                <li><a href="http://vizagbikerentals.com/bike-rentals-in-vizag">Bike Rentals near R.T.C Complex, Vizag</a></li>
                <li><a href="http://vizagbikerentals.com/bike-rentals-in-vizag">Bike Rentals near R.K Beach, Vizag</a></li>
                <li><a href="http://vizagbikerentals.com/bike-rentals-in-vizag">Bike Rentals in Aruku</a></li>
                <li><a href="http://vizagbikerentals.com/bike-rentals-in-vizag">Bike Rentals in Vizag</a></li>
                <li><a href="http://vizagbikerentals.com/bike-rentals-in-vizag">Car Rentals in Vizag</a></li>
              </ul>
            </article>
          </IonCol>
          <IonCol sizeMd="3" sizeSm="12" sizeXs="12">
            <article className="info">
              <h4>Follow US</h4>
              <ul>
                <li>
                  <a href="http://vizagbikerentals.com">Youtube</a>
                </li>
                <li>
                  <a href="http://vizagbikerentals.com">Facebook</a>
                </li>
                <li>
                  <a href="http://vizagbikerentals.com/about">Instagram</a>
                </li>
              </ul>
            </article>
          </IonCol>
          <IonCol sizeMd="3" sizeSm="12" sizeXs="12">
            <article className="info">
              <h4>Contact US</h4>
              <ul>
                <li>
                  <p>
                    <strong>
                      <IonIcon name="time-outline"></IonIcon>
                    </strong>
                    &nbsp;&nbsp;6AM Open - 8PM Close
                  </p>
                </li>
                <li>
                  <p>
                    <strong>
                      <IonIcon name="call-outline"></IonIcon>
                    </strong>
                    &nbsp;&nbsp;+91 9577677779
                  </p>
                </li>
                <li>
                  <>
                    <strong>
                      <IonIcon name="storefront-outline"></IonIcon>
                    </strong>
                    &nbsp;&nbsp;
                    <address>43,43-23, 23, junction, Dondaparthy, Railway New Colony, Visakhapatnam, Andhra Pradesh 530016</address>
                  </>
                </li>
              </ul>
            </article>
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonGrid style={{ background: "rgb(24 24 24)", color: "#fff" }}>
        <IonRow>
          <IonCol sizeMd="12" sizeSm="12" sizeXs="12">
            <article style={{ margin: "auto" }}>
              <p style={{ textAlign: "center", fontSize: "14px" }}>
                Copyright © 2017 -{" "}
                <a href="http://vizagbikerentals.com">Chalo Ride{"  "}</a> - All rights
                reserved.
              </p>
            </article>
          </IonCol>
        </IonRow>
      </IonGrid>
    </footer>
  );
};

export default Footer;
